<template>
  <images-upload
    v-model="obModel"
    cols="12"
    lg="6"
    hide-images
    @update:preview-image="onChangeImage"
  />
</template>

<script lang="ts">
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { Company } from "@planetadeleste/vue-mc-gw";
import { delay } from "lodash";
import type { RequestOptions, Response } from "vue-mc";
import { Vue, Component, VModel } from "vue-property-decorator";

@Component
export default class CompanyImage extends Vue {
  @VModel({ type: Object, default: () => new Company() }) obModel!: Company;
  loading = false;

  async onChangeImage(obFile: File) {
    if (!this.obModel.id || this.loading || this.sImage) {
      return;
    }

    this.loading = true;
    const obCompany = new Company({ id: this.obModel.id });
    const obOptions: RequestOptions = {
      method: "POST",
      url: obCompany.getURL("companies.attach", { id: this.obModel.id }),
      data: { preview_image: obFile, id: this.obModel.id },
    };

    const obResponse: Response<CompanyData> | null = await obCompany.store(
      obOptions
    );

    if (obResponse && obResponse.getData()) {
      const sImage = obResponse.getData().data.preview_image;
      if (sImage) {
        this.obModel.set("preview_image", sImage);
        this.$emit("input", this.obModel);
      }
    }

    delay(() => {
      this.loading = false;
    }, 500);
  }

  get sImage(): string | File | null {
    return this.obModel.get("preview_image", null);
  }
}
</script>
